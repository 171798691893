import Layout from '@/components/Layout';
import Modal from '@/components/Modal';
import SEO from '@/components/SEO';
import CheckboxInput from '@/components/form/CheckboxInput';
import TextInput from '@/components/form/TextInput';
import { SAVED_DETAILS_KEY } from '@/lib';
import { useModal } from '@/lib/modal';
import { algoliaAnalytics } from '@/lib/search';
import { ShoppingBasketContext } from '@/lib/shoppingBasket';
import { getUserID } from '@/lib/tracking';
import { RefreshIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { Link, navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

const CheckoutQuotePage: FC<PageProps> = () => {
  const [, setError] = useState<string>();
  const successModal = useModal();

  const { items, clearBasket } = useContext(ShoppingBasketContext);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      promoCode: '',
      toc: false,
      rememberMe: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please provide your name'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Please provide your email address'),
      phoneNumber: Yup.string().required('Please provide your phone number'),
      toc: Yup.boolean()
        .isTrue('You must accept T&C')
        .required('You must accept T&C'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setError(undefined);
      setSubmitting(true);

      try {
        if (values.rememberMe) {
          localStorage.setItem(
            SAVED_DETAILS_KEY,
            JSON.stringify({
              email: values.email,
              name: values.name,
              phoneNumber: values.phoneNumber,
            })
          );
        } else {
          localStorage.removeItem(SAVED_DETAILS_KEY);
        }
      } catch {
        // ata ete, n ai csf
      }

      try {
        const formData = new URLSearchParams();

        formData.append('email', values.email.replace('@', `+${Date.now()}@`));
        formData.append('name', values.name);
        formData.append('custom_phone_number', values.phoneNumber);
        formData.append('custom_promo_code', values.promoCode);
        formData.append(
          'custom_requested_quote',
          items
            .map(
              (item) =>
                `[${item.quantity}] ${item.product.code}: ${item.resellers.join(
                  ', '
                )}`
            )
            .join('; ')
        );

        formData.append('start_day', '0'); // set autoresponder to 0
        formData.append('campaign_token', 'reT9k'); // OptiOffer Quote Requests
        formData.append('webform[consent#honK-ver#zqHo]', `${values.toc}`); // T&C consent field

        await fetch('https://app.getresponse.com/add_subscriber.html', {
          mode: 'no-cors',
          method: 'POST',
          body: formData,
        });

        successModal.open();
      } catch (ex) {
        setError(ex?.toString());
      }

      try {
        algoliaAnalytics(
          'convertedObjectIDsAfterSearch',
          ...items
            .filter((item) => item.queryId)
            .map((item) => ({
              index: process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX_NAME,
              eventName: 'Quote Requested',
              queryID: item.queryId,
              objectIDs: [item.product.path],
              userToken: getUserID(),
            }))
        );
      } catch {
        // ata ete, n ai csf
      }

      try {
        await clearBasket();
      } catch {
        // ata ete, n ai csf
      }

      setSubmitting(false);
    },
  });
  const formikRef = useRef(formik);

  useEffect(() => {
    const serializedData = localStorage.getItem(SAVED_DETAILS_KEY);
    if (!serializedData) return;
    const savedData = JSON.parse(serializedData);
    formikRef.current.resetForm({
      values: {
        ...savedData,
        promoCode: '',
        toc: true,
        rememberMe: true,
      },
    });
  }, [formikRef]);

  return (
    <Layout fsoHeader minimalFooter mainClassName="relative min-h-screen">
      <SEO
        url="ro/oferta-mea/checkout"
        title="Finalizeaza cererea de oferta"
        description=""
        disableIndex
        lang="ro"
      />
      <div className="m-auto w-full max-w-5xl px-4 lg:p-0 ">
        <section className="pt-6 pb-7 max-w-lg min-h-screen flex flex-col">
          <div className="font-semibold text-xl text-primary-300 mb-5">
            Cerere de oferta
          </div>
          <div className="font-semibold text-base text-brown mb-5">
            Unde doresti sa te contactam despre oferta de pret?
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="flex-1 flex flex-col gap-4 mb-4"
          >
            <TextInput
              formik={formik}
              name="name"
              label="Nume"
              placeholder="ex: Melon Musk"
              className=""
            />
            <TextInput
              formik={formik}
              name="email"
              label="Email"
              placeholder="ex: melon@spaceY.com"
              className=""
            />
            <TextInput
              formik={formik}
              name="phoneNumber"
              label="Numar de telefon"
              placeholder="ex: 07xx xxx xxx"
              className=""
            />
            <TextInput
              formik={formik}
              name="promoCode"
              label="Cod promotional"
              className=""
            />
            <CheckboxInput formik={formik} name="toc" className="">
              Accept
              <Link
                to="/terms-and-conditions/"
                target="_blank"
                className="ml-1 underline"
              >
                Termenii si Conditiile
              </Link>
            </CheckboxInput>
            <CheckboxInput formik={formik} name="rememberMe">
              Tine minte datele pentru urmatoarea oferta
            </CheckboxInput>

            <div className="flex-1 lg:hidden" />
            <div className="sticky lg:static bottom-0 mt-6 pb-4">
              <button
                id="requestQuote"
                className={clsx(
                  'w-full bg-yellow font-semibold flex items-center justify-center px-4 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base',
                  formik.isSubmitting ? 'bg-gray-300' : 'bg-yellow'
                )}
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <RefreshIcon className="animate animate-spin h-6" />
                ) : (
                  'Trimite cererea de ofera'
                )}
              </button>
            </div>
          </form>
        </section>
      </div>

      <Modal
        control={successModal}
        title="Multumim! Cerea a fost inregistrata."
        renderActions={(props) => (
          <button
            {...props}
            className={clsx(
              props.className,
              'bg-primary hover:bg-primary-200 text-white'
            )}
            type="button"
            onClick={() => {
              successModal.close();
              return navigate('/ro/');
            }}
          >
            OK
          </button>
        )}
      >
        <p
          id="requestQuoteSuccessMessage"
          className="font-semibold mb-4 text-base"
        >
          Furnizorii pe care i-ai selectat vor reveni cu o oferta in maxim 24h.
        </p>
        <p className="mb-8 ">
          Suntem aici pentru tine. Daca ai intrebari sau nelamuriri nu ezita sa
          ne contactezi la{' '}
          <a
            href="mailto:support@optioffer.com"
            className="text-primary underline"
          >
            support@optioffer.com
          </a>{' '}
          sau{' '}
          <a href="tel:+40747761756" className="text-primary underline">
            0747 761 756
          </a>
          .
        </p>
      </Modal>
    </Layout>
  );
};

export default CheckoutQuotePage;
